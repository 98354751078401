const OneConfigEmbed = {
  scriptId: "oneConfigSnippet",
  containerId: "oneConfigEmbed",
  bucketURL: "BUCKET_URL",
  translationUrl: "TRANSLATION_URL",
  retryCount: 0,
  bundleReady: false,
  listener: function (event) {
    if (!(event.data && event.data.type)) return;
    const { payload } = event.data;
    if (event.data.type === "ONE_CONFIG_SAVE_CONFIG") {
      OneConfigEmbed._saveConfig(payload, event.data.actionType);
    } else if (event.data.type === "ONE_CONFIG_BOM_UPDATE") {
      OneConfigEmbed._bomUpdate(payload);
    } else if (event.data.type === "REQUEST_CONFIG_SEND_MAIL") {
      OneConfigEmbed._sendMail(payload);
    } else if (event.data.type === "LAUNCH_SMARTSEARCH") {
      const rSKUData = {
        action: "showSmartSearch",
        data: payload
      };
      if (typeof OneConfigEmbed.onSearchSKU === "function")
        OneConfigEmbed.onSearchSKU(rSKUData);
    } else if (event.data.type === "ADD_RSKU_TO_QUOTE") {
      if (typeof OneConfigEmbed.onAddRSKUToQuote === "function")
        OneConfigEmbed.onAddRSKUToQuote(payload);
    }
  },
  _patchProcess: function (init) {
    window.process = {
      env: {
        NODE_ENV: "production"
      }
    };
    return init;
  },
  _validateInit: function (init) {
    const requiredFields = [
      "language",
      "configId",
      "country",
      "originatingAsset"
    ];
    const errors = [];
    for (const field of requiredFields) {
      if (typeof init[field] !== "string") {
        errors.push(`${field} is a required field`);
      }
    }
    if (errors.length > 0) {
      throw new Error(errors.join("\n"));
    }
    return init;
  },
  _applyStyles: function (init) {
    const {
      primary,
      secondary,
      primaryHover,
      secondaryHover,
      primaryLoader,
      primaryError
    } = init.colourOverrides || {};
    document.documentElement.style.setProperty(
      "--one-config-primary",
      primary || "#0096d6"
    );
    document.documentElement.style.setProperty(
      "--one-config-secondary",
      secondary || "#d7410b"
    );
    document.documentElement.style.setProperty(
      "--one-config-primaryHover",
      primaryHover || "#075782"
    );
    document.documentElement.style.setProperty(
      "--one-config-secondaryHover",
      secondaryHover || "#e0f2fe"
    );
    document.documentElement.style.setProperty(
      "--one-config-primaryLoader",
      primaryLoader || "#22c55e"
    );
    document.documentElement.style.setProperty(
      "--one-config-primaryError",
      primaryError || "#D84646"
    );
    return init;
  },
  _downloadExternalRss: function (init) {
    if (!document.getElementById(this.scriptId)) {
      const script = document.createElement("script");
      script.id = this.scriptId;
      script.src = this.bucketURL + "bundle.js";
      script.onload = () => (OneConfigEmbed.bundleReady = true);
      document.body.appendChild(script);
      if (!init.fontSupplied) {
        const style = document.createElement("style");
        style.innerHTML = `@import url(${this.bucketURL}font-family.css)`;
        document.head.appendChild(style);
      }
    }
    this[this.modelCacheKey] = "";
    this[this.translationCacheKey] = "";
    this[this.refConfigCacheKey] = "";
    this[this.headerCacheKey] = "";
    this[this.uploadCacheKey] = "";
    this[this.ocfgCacheKey] = "";
    return init;
  },
  _moCallback: function (mutationList) {
    for (const mutation of mutationList) {
      if (mutation.type === "childList" && mutation.removedNodes.length) {
        const configuratorRemoved = Array.from(
          mutation.removedNodes.values()
        ).some(node => node.tagName === "ONE-CONFIG");
        if (configuratorRemoved) OneConfigEmbed.cleanUp();
      }
    }
  },
  _observe: function (init) {
    if (!OneConfigEmbed.observer) {
      OneConfigEmbed.observer = new MutationObserver(
        OneConfigEmbed._moCallback
      );
    }
    window.addEventListener("message", this.listener);
    OneConfigEmbed.observer.observe(
      document.getElementById(OneConfigEmbed.containerId),
      { childList: true }
    );
    return init;
  },
  extractOCFGParams: function (ocfgParams, filterLevel) {
    let filteredOCFG = [ocfgParams];
    filterLevel.forEach(val => {
      filteredOCFG = filteredOCFG
        .flatMap(item => [item?.[val], item?.["ALL"] || item?.["all"]])
        .filter(value => value !== undefined);
    });

    const reduceToObj = key =>
      filteredOCFG.reduce((pre, cur) => ({ ...cur?.[key], ...pre }), {});

    return {
      monitorQty: reduceToObj("Monitor_Qty"),
      hideTab: reduceToObj("hideTab"),
      hideChoice: reduceToObj("hideChoice")
    };
  },
  init: async function (initConfig) {
    if (initConfig.isUploadFlow) return this.uploadInit(initConfig);
    if (!initConfig.isUploadFlow && initConfig.bomList && !initConfig.configId)
      return this.reConfigureInt(initConfig);

    try {
      this._downloadExternalRss(
        this._applyStyles(this._patchProcess(this._validateInit(initConfig)))
      );
    } catch (e) {
      return Promise.reject(e);
    }
    const {
      token,
      gateway,
      enableExport,
      enableBomDeltaPrice,
      saveConfigCb,
      modelDataFailCb,
      addToCart,
      bomUpdateCb,
      heartbeatCb,
      showSustainability,
      showProductSummary,
      showCLIN,
      showOM,
      showOpenMarket,
      colourOverrides,
      showHeader,
      buttonTextToCart,
      isBomCb,
      showOCFeatures,
      isReconfigure,
      retainConfigID,
      updateConfig,
      hideTechAV,
      exitCb,
      requestConfigCb,
      pricingCb,
      showRequestConfig,
      showListPriceText,
      showStartingPointPrice,
      showSaveConfig,
      showReset,
      companyId,
      storeFront,
      removeDuplicateSKU,
      productAvailability,
      scrollToChoice,
      showSearchSKU,
      showRequestSKU,
      userEmail,
      onSearchSKU,
      onAddRSKUToQuote,
      spcStartDate,
      ...config
    } = initConfig;
    const { language, productType, region, originatingAsset } = config;
    this.productType = productType;
    this.region = region;
    this.originatingAsset = originatingAsset;
    OneConfigEmbed.heartbeatCb = heartbeatCb;
    OneConfigEmbed.saveConfigCb = saveConfigCb || addToCart;
    OneConfigEmbed.modelDataFailCb = modelDataFailCb;
    OneConfigEmbed.bomUpdateCb = bomUpdateCb;
    OneConfigEmbed.onSearchSKU = onSearchSKU;
    OneConfigEmbed.onAddRSKUToQuote = onAddRSKUToQuote;
    OneConfigEmbed.gateway = gateway;
    OneConfigEmbed.token = token;
    OneConfigEmbed.enableExport = enableExport;
    OneConfigEmbed.spcStartDate = spcStartDate;
    OneConfigEmbed.config = {
      banded: false,
      configId: config.configId,
      country: config.country,
      isStandAloneAccess: false,
      language: config.language,
      marketProgramName: config.marketProgramName,
      marketProgramType: config.marketProgramType,
      mdcpOrgId: config.mdcpOrgId,
      originatingAsset: config.originatingAsset,
      priceListType: config.priceListType,
      productType: config.productType,
      region: config.region,
      resellerId: config.resellerID || "",
      routeToMarket: config.routeToMarket,
      storeFront: storeFront || "WCS",
      userType: config.userType || "PARTNER"
    };

    if (config.priceDesc) {
      OneConfigEmbed.config.priceDesc = config.priceDesc;
      OneConfigEmbed.config.priceId = config.priceId;
      OneConfigEmbed.config.contractId = config.contractId || "";
      OneConfigEmbed.config.openMarketId = config.openMrktId || "";
      if (!showCLIN) OneConfigEmbed.config.contractId = "";
    } else {
      // assume pricingGeo, currency, incoterm is used to define price data
      OneConfigEmbed.config.pricingGeo = config.pricingGeo;
      OneConfigEmbed.config.incoTerms = config.incoTerms;
      OneConfigEmbed.config.currency = config.currency;
    }
    OneConfigEmbed.categoryName = "Business Laptop PCs";
    OneConfigEmbed.showCLIN = showCLIN;
    OneConfigEmbed.showOM = showOpenMarket || showOM || false;
    OneConfigEmbed.showHeader = showHeader;
    OneConfigEmbed.updateConfig = updateConfig;
    OneConfigEmbed.showOCFeatures = showOCFeatures;
    OneConfigEmbed.isReconfigure = isReconfigure;
    OneConfigEmbed.retainConfigID = retainConfigID;
    OneConfigEmbed.hideTechAV = hideTechAV;
    OneConfigEmbed.buttonTextToCart = buttonTextToCart;
    OneConfigEmbed.showProductSummary = showProductSummary;
    OneConfigEmbed.showSustainability = showSustainability;
    OneConfigEmbed.language = language;
    OneConfigEmbed.enableBomDeltaPrice = enableBomDeltaPrice;
    OneConfigEmbed.pricingCb = pricingCb;
    OneConfigEmbed.isBomCb = isBomCb;
    OneConfigEmbed.exitCb = exitCb;
    OneConfigEmbed.requestConfigCb = requestConfigCb;
    OneConfigEmbed.showRequestConfig = showRequestConfig;
    OneConfigEmbed.showListPriceText = showListPriceText;
    OneConfigEmbed.showStartingPointPrice = showStartingPointPrice;
    OneConfigEmbed.showSaveConfig = showSaveConfig;
    OneConfigEmbed.showReset = showReset;
    OneConfigEmbed.companyId = companyId;
    OneConfigEmbed.productAvailability = productAvailability || false;
    OneConfigEmbed.storeFront = storeFront || "WCS";
    OneConfigEmbed.removeDuplicateSKU = removeDuplicateSKU;
    OneConfigEmbed.scrollToChoice = scrollToChoice;
    OneConfigEmbed.showSearchSKU = showSearchSKU;
    OneConfigEmbed.showRequestSKU = showRequestSKU;
    OneConfigEmbed.userEmail = userEmail;
    try {
      await this._bootstrap();
      return Promise.resolve("configurator started");
    } catch (e) {
      return Promise.reject(e);
    }
  },
  _modelDataReady: function () {
    if (this.modelDataReady) return;

    if (this.enableBomDeltaPrice) {
      if (window.spcDetails && window.modelJson) {
        if (window.pricing) {
          window.spcDetails.items = window.spcDetails.items.map(item =>
            updateItemPrice(item)
          );
          window.modelJson.items = window.modelJson.items.map(item =>
            updateItemPrice(item)
          );
        }
        postMessageAndReset.call(this);
      }
    } else {
      if (window.modelJson) {
        if (window.pricing) {
          window.modelJson.items = window.modelJson.items.map(item =>
            updateItemPrice(item)
          );
        }
        postMessageAndReset.call(this);
      }
    }

    function updateItemPrice(item) {
      const priceFromPartner = window.pricing[item.partno];
      if (priceFromPartner) {
        item.netPrice = priceFromPartner;
      } else {
        item.netPrice = "";
        item.priceStatus = "I";
        item.visible = false;
      }
      return item;
    }

    function postMessageAndReset() {
      window.postMessage({
        type: "ONE_CONFIG_MODEL_DATA_READY"
      });
      this.modelDataReady = true;
      this.retryCount = 0;
    }
  },
  _modelDataFailure: function (err) {
    if (OneConfigEmbed.modelDataFailCb) {
      OneConfigEmbed.modelDataFailCb(err);
    } else if (document.querySelector("one-config")) {
      window.postMessage({
        type: "ONE_CONFIG_MODEL_DATA_FAILED"
      });
    } else {
      OneConfigEmbed._showGenericErrorNotification();
    }
  },
  _showGenericErrorNotification: function (errorMessage) {
    const notificationID = "configNotification";
    if (
      document.querySelector("one-config") ||
      document.getElementById(notificationID)
    ) {
      return;
    }
    const notificationDuration = 3000;
    const notificationElement = document.createElement("div");
    notificationElement.id = notificationID;
    notificationElement.setAttribute(
      "style",
      "position: fixed;display: flex;justify-content: space-between;align-items: flex-start;min-width: fit-content;padding-left: 1rem;padding-right: 1rem;padding-top: 0.75rem;padding-bottom: 0.75rem;border-radius: 0.25rem;color:#ffffff;gap: 1rem;top: 10rem;left: 0.75rem;background-color: #fd0032;z-index: 1;"
    );
    notificationElement.textContent =
      errorMessage || "An error occurred while loading your product's data.";
    document.getElementById("oneConfigEmbed").appendChild(notificationElement);
    setTimeout(() => {
      document.getElementById(notificationID)?.remove();
    }, notificationDuration);
  },
  _bootstrap: async function () {
    this._observe();
    const languageCode = this.getLanguageCode();
    if (this.pricingCb) {
      Promise.all([
        this._errorHandling(this._getModel, this.modelCacheKey),
        this.pricingCb()
      ])
        .then(([model, pricing]) => {
          if (model.data) {
            window.modelJson = model.data.simpleModelConfiguration;
            window.pricing = pricing.reduce((acc, item) => {
              acc[item.partNumber] = item.price;
              return acc;
            }, {});
            this._modelDataReady();
          } else {
            this._modelDataFailure();
          }
        })
        .catch(this._modelDataFailure);
    } else {
      this._errorHandling(this._getModel, this.modelCacheKey)
        .then(model => {
          if (model.data) {
            window.modelJson = model.data.simpleModelConfiguration;
            this._modelDataReady();
          } else {
            this._modelDataFailure();
          }
        })
        .catch(this._modelDataFailure);
    }
    const [headerInfo, translations] = await Promise.all([
      this._errorHandling(this._getHeaderInfo, this.headerCacheKey),
      this._errorHandling(
        this._getTranslation.bind(this, languageCode),
        this.translationCacheKey
      )
    ]);
    if (this.bundleReady) {
      this._launchConfigurator(headerInfo, translations, languageCode);
    } else {
      return new Promise(resolve => {
        document.getElementById(this.scriptId).onload = () => {
          setTimeout(() => {
            OneConfigEmbed.bundleReady = true;
            resolve(
              OneConfigEmbed._launchConfigurator(
                headerInfo,
                translations,
                languageCode
              )
            );
          }, 0);
        };
      });
    }

    if (this.enableBomDeltaPrice) {
      this._errorHandling(
        this._getSpcDetails.bind(
          this,
          headerInfo.data.simpleModelConfiguration
        ),
        this.refConfigCacheKey
      )
        .then(spcRes => {
          if (spcRes.data) {
            window.spcDetails = spcRes.data.simpleModelConfiguration;
            this._modelDataReady();
          } else {
            this._modelDataFailure();
          }
        })
        .catch(this._modelDataFailure);
    }
  },
  modelCacheKey: "modelReq",
  headerCacheKey: "headerReq",
  uploadCacheKey: "uploadReq",
  ocfgCacheKey: "ocfgReq",
  translationCacheKey: "translationReq",
  refConfigCacheKey: "refConfigReq",
  _launchConfigurator: function (headerInfo, translations, languageCode) {
    window.productImage = headerInfo.data.configImage;
    const asset =
      this.storeFront.toUpperCase() === "ECOMM"
        ? "ECOMM"
        : this.originatingAsset.toUpperCase() || "OCIQ";
    const filterLevel = [
      asset,
      this.productType,
      this.region,
      this.config.country,
      this.config.userType.toLowerCase()
    ];

    const { monitorQty, hideTab, hideChoice } = this.extractOCFGParams(
      headerInfo.data.productSelectionV2.ocfgParams || {},
      filterLevel
    );
    window.app({
      headerInfo: headerInfo.data.simpleModelConfiguration,
      showCLIN: this.showCLIN,
      showSustainability: this.showSustainability,
      showOM: this.showOM,
      monitorQty,
      hideTab,
      hideChoice,
      showProductSummary: this.showProductSummary,
      showHeader: this.showHeader,
      buttonTextToCart: this.buttonTextToCart,
      enableBomDeltaPrice: this.enableBomDeltaPrice,
      enableExport: this.enableExport,
      updateConfig: this.updateConfig,
      translations,
      languageCode,
      language: this.config.language,
      modelAvailable: this.modelDataReady,
      showOCFeatures: this.showOCFeatures,
      isReconfigure: this.isReconfigure,
      retainConfigID: this.retainConfigID,
      region: this.region,
      hideTechAV: this.hideTechAV,
      showCancelConfig: typeof this.exitCb === "function",
      showRequestConfig: this.showRequestConfig,
      showListPriceText: this.showListPriceText,
      showStartingPointPrice: this.showStartingPointPrice,
      showSaveConfig: this.showSaveConfig,
      showReset: this.showReset,
      companyId: this.companyId,
      removeDuplicateSKU: this.removeDuplicateSKU,
      originatingAsset: this.originatingAsset,
      storeFront: this.storeFront,
      productAvailability: this.productAvailability,
      scrollToChoice: this.scrollToChoice,
      productType: this.productType,
      showSearchSKU: this.showSearchSKU,
      showRequestSKU: this.showRequestSKU,
      userEmail: this.userEmail,
      userType: this.config.userType,
      businessModel: this.config.routeToMarket,
      spcStartDate: this.spcStartDate,
      country: this.config.country,
      mdcpOrgId: this.config.mdcpOrgId
    });
  },
  getLanguageCode: function () {
    const languageTable = {
      cs_CZ: "cs_CZ",
      de_DE: "de",
      el_GR: "el_GR",
      en_US: "en",
      es_ES: "es",
      es_MX: "es_MX",
      fr_FR: "fr",
      fr_CA: "fr_CA",
      hu_HU: "hu_HU",
      in_ID: "in_ID",
      it_IT: "it",
      ko_KR: "ko_KR",
      nl_NL: "nl_NL",
      pl_PL: "pl_PL",
      pt_BR: "pt_BR",
      ru_RU: "ru_RU",
      sk_SK: "sk_SK",
      sv_SE: "sv_SE",
      th_TH: "th_TH",
      tr_TR: "tr_TR",
      vi_VN: "vi_VN",
      zh_CN: "zh_CN",
      zh_TW: "zh_TW"
    };

    return languageTable[this.config.language] || "en";
  },
  _getTranslation: function (fileName) {
    return fetch(`${this.translationUrl}${fileName}.json`);
  },
  _errorHandling: async function (request, cacheKey) {
    if (this[cacheKey]) return JSON.parse(this[cacheKey]);
    const response = await request.call(this);
    if (!response.ok) {
      OneConfigEmbed._showGenericErrorNotification("Request failed!");
      throw new Error(await response.text());
    }
    const resString = await response.text();
    const responseJson = JSON.parse(resString);
    if (responseJson.errors) {
      !OneConfigEmbed.showOCFeatures &&
        OneConfigEmbed._showGenericErrorNotification("Request failed!");
      throw new Error(responseJson.errors.map(error => error.message));
    } else {
      OneConfigEmbed[cacheKey] = resString;
    }
    return responseJson;
  },
  _getOCFGInfo: function () {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `query ( $productSelectionFilter: ProductSelectionRequestV2! ) { productSelectionV2(filter: $productSelectionFilter) { ocfgParams }}`,
        variables: {
          productSelectionFilter: {
            region: this.modelConfig.region
          },
          showHeader: this.showHeader || false
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getHeaderInfo: function () {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "HEADER_INFO_QUERY",
        variables: {
          filter: this.config,
          productSelectionFilter: {
            region: this.config.region
          },
          configID: this.config.configId,
          showHeader: this.showHeader || false
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getModel: function () {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "SIMPLE_MODEL_CONFIGURATION_API_QUERY",
        variables: {
          filter: this.config,
          showOM: this.showOM,
          showProductAvailability: this.productAvailability,
          showOCFeatures: this.showOCFeatures || false
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getSpcDetails: function (headerInfo) {
    const payload = {
      ...this.config,
      configId:
        headerInfo.docType === "SP"
          ? headerInfo.configId
          : headerInfo.refConfigId
    };
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "MATINFO_SIMPLE_MODEL_API_QUERY",
        variables: { filter: payload }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getCategory: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `query catalogLookup($filter: CatalogLookupRequest){catalogLookup(filter: $filter)}`,
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getChoiceID: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `query getChoiceID($category: String!){getMandatoryChoiceIDs(category: $category)}`,
        variables: {
          category: payload
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getRskuImage: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `query getimage($skuNumbers: [String!]!) {
          getProductImages(skuNumbers: $skuNumbers) {
            httpsUrl
            orientation
            backGround
          }
        }`,
        variables: {
          skuNumbers: payload.skuNumbers
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _getRskuMatch: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "RSKU_MATCH_API_QUERY",
        variables: payload
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _createRSKU: function (payload) {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "CREATE_RSKU_API_MUTATION",
        variables: { input: payload }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _uploadBootstrap: async function () {
    const CATEGORY_MAP = {
      "Business Laptop PCs": "notebook",
      "Business Desktop PCs": "desktop",
      "Mobile Workstations": "mobilews",
      Workstations: "workstation",
      "Point of Sale Solutions": "pointofsale",
      "Voice Systems": "voiceSystems",
      "Video Systems": "videoSystems",
      Infrastructure: "infrastructure"
    };
    const DEFAULT_CATEGORY_NAME = "Business Laptop PCs";
    const getUiDataFileName = category => {
      if (CATEGORY_MAP[category]) return CATEGORY_MAP[category];

      const isPrinterCategory =
        category.toLowerCase().includes("printer") ||
        category.toLowerCase().includes("color laserjet");

      return isPrinterCategory ? "printer" : "desktop";
    };

    this._observe();
    const languageCode = this.getLanguageCode();
    const [ocfgInfo, translations] = await Promise.all([
      this._errorHandling(this._getOCFGInfo, this.ocfgCacheKey),
      this._errorHandling(
        this._getTranslation.bind(this, languageCode),
        this.translationCacheKey
      )
    ]);
    try {
      const { data } = await this._errorHandling(
        this._uploadConfigRestoration,
        this.uploadCacheKey
      );
      const {
        isValid,
        currency,
        ipgLocOptions,
        kybdLocOptions,
        monitorLocOptions,
        osLocOptions,
        modelRequirement,
        systemLocOptions,
        configName,
        category
      } = data.uploadConfigRestoration.modelData;
      const categoryName = category || DEFAULT_CATEGORY_NAME;
      OneConfigEmbed.categoryName = categoryName;
      const headerInfo = {
        ...ocfgInfo.data.productSelectionV2,
        isValid,
        currency,
        ipgLocOptions,
        kybdLocOptions,
        monitorLocOptions,
        osLocOptions,
        modelRequirement,
        systemLocOptions,
        configId: this.configId || "",
        configName: this.configName || configName || "N/A",
        refConfigId: "",
        uiSpec: getUiDataFileName(categoryName),
        docType: "QU"
      };
      if (this.bundleReady) {
        this._uploadConfigurator(headerInfo, translations, languageCode);
      } else {
        return new Promise(resolve => {
          document.getElementById(this.scriptId).onload = () => {
            setTimeout(() => {
              OneConfigEmbed.bundleReady = true;
              resolve(
                this._uploadConfigurator(headerInfo, translations, languageCode)
              );
            }, 0);
          };
        });
      }
      const { modelData } = data.uploadConfigRestoration;
      const configHeader = {
        configType: "custom",
        configHighLights: "",
        configImage: "",
        kmatId: modelData.kmatId || "",
        programName: "",
        customerCompanyName: "",
        mdcpOrgId: this.config.mdcpOrgId || "",
        regionCode: modelData.region || "",
        lead_locale: "",
        autoLocalized: "",
        kbVersion: "",
        marketProgramName: "",
        marketProgramType: "",
        shipmentDate: "",
        exportStatusID: "new",
        bandingEol: null,
        configEol: null,
        businessModel: "indirect",
        copiedFrom: "",
        localizationType: "",
        priceGeo: modelData.priceGeo,
        currencyCd: modelData.currency,
        incoterm: modelData.incoTerm,
        priceListType: modelData.priceListType,
        globalFl: "N",
        activeFl: "Y",
        delFl: "N",
        productLine: modelData?.modelRequirement?.productGroup || "NA",
        storeFront: "IQ",
        spcType: "C"
      };
      window.modelJson = {
        ...data.uploadConfigRestoration.modelData,
        configId: this.configId || "",
        configName: this.configName || configName || "N/A",
        noneChaids: {},
        configuration: { configHeader }
      };
      setTimeout(() => {
        window.postMessage({
          type: "ONE_CONFIG_MODEL_DATA_READY"
        });
        this.modelDataReady = true;
        this.retryCount = 0;
      }, 0);
    } catch (err) {
      this._modelDataFailure(err);
      throw new Error(err);
    }
  },
  uploadConfigEmbedSetup: function (initConfig) {
    const {
      language,
      productType,
      region,
      originatingAsset,
      modelId,
      mdcpOrgId,
      country,
      resellerID,
      userType,
      shipmentDate,
      routeToMarket,
      marketProgramType,
      marketProgramName,
      bom,
      bomQty
    } = initConfig;

    const DEFAULT_STORE_FRONT = "WCS";
    OneConfigEmbed.configName = initConfig.configName || "";
    OneConfigEmbed.configId = initConfig.configId;
    OneConfigEmbed.saveConfigCb =
      initConfig.saveConfigCb || initConfig.addToCart;
    OneConfigEmbed.modelDataFailCb = initConfig.modelDataFailCb;
    OneConfigEmbed.onSearchSKU = initConfig.onSearchSKU;
    OneConfigEmbed.onAddRSKUToQuote = initConfig.onAddRSKUToQuote;
    OneConfigEmbed.gateway = initConfig.gateway;
    OneConfigEmbed.token = initConfig.token;
    OneConfigEmbed.enableExport = initConfig.enableExport;
    OneConfigEmbed.showOCFeatures = initConfig.showOCFeatures;
    OneConfigEmbed.isReconfigure = initConfig.isReconfigure;
    OneConfigEmbed.buttonTextToCart = initConfig.buttonTextToCart;
    OneConfigEmbed.showProductSummary = initConfig.showProductSummary;
    OneConfigEmbed.language = initConfig.language;
    OneConfigEmbed.isBomCb = initConfig.isBomCb;
    OneConfigEmbed.requestConfigCb = initConfig.requestConfigCb;
    OneConfigEmbed.companyId = initConfig.companyId;
    OneConfigEmbed.storeFront = initConfig.storeFront || DEFAULT_STORE_FRONT;
    OneConfigEmbed.scrollToChoice = initConfig.scrollToChoice;
    OneConfigEmbed.showSearchSKU = initConfig.showSearchSKU;
    OneConfigEmbed.showRequestSKU = initConfig.showRequestSKU;
    OneConfigEmbed.userEmail = initConfig.userEmail;
    OneConfigEmbed.productType = initConfig.productType;
    OneConfigEmbed.region = region;
    OneConfigEmbed.isUploadFlow = initConfig.isUploadFlow;
    OneConfigEmbed.isSKUIDClone = initConfig.isSKUIDClone;
    OneConfigEmbed.skuId = initConfig.skuId;
    OneConfigEmbed.originatingAsset =
      initConfig.originatingAsset === "RSKU"
        ? "OCIQ"
        : initConfig.originatingAsset;
    OneConfigEmbed.config = {
      country,
      region,
      mdcpOrgId,
      language
    };
    OneConfigEmbed.modelConfig = {
      region,
      language,
      productType,
      originatingAsset,
      modelId,
      country,
      mDCPOrgID: mdcpOrgId,
      resellerId: resellerID || "",
      userType,
      shipmentDate,
      routeToMarket,
      marketProgramType,
      marketProgramName,
      bom,
      bomQty
    };
  },
  uploadInit: async function (initConfig) {
    try {
      this._downloadExternalRss(
        this._applyStyles(this._patchProcess(initConfig))
      );
      this.uploadConfigEmbedSetup(initConfig);
      await this._uploadBootstrap();
      return Promise.resolve("uploadConfigurator started");
    } catch (e) {
      return Promise.reject(e);
    }
  },
  reConfigureEmbedSetup: function (initConfig) {
    const {
      token,
      gateway,
      enableExport,
      enableBomDeltaPrice,
      saveConfigCb,
      modelDataFailCb,
      addToCart,
      bomUpdateCb,
      heartbeatCb,
      showSustainability,
      showProductSummary,
      showCLIN,
      showOM,
      showOpenMarket,
      colourOverrides,
      showHeader,
      buttonTextToCart,
      isBomCb,
      showOCFeatures,
      isReconfigure,
      retainConfigID,
      updateConfig,
      hideTechAV,
      exitCb,
      requestConfigCb,
      pricingCb,
      showRequestConfig,
      showListPriceText,
      showStartingPointPrice,
      showSaveConfig,
      showReset,
      companyId,
      storeFront,
      removeDuplicateSKU,
      productAvailability,
      scrollToChoice,
      showSearchSKU,
      showRequestSKU,
      userEmail,
      onSearchSKU,
      onAddRSKUToQuote,
      spcStartDate,
      enableLocalization,
      bomList,
      ...config
    } = initConfig;
    const { language, productType, region, originatingAsset } = config;
    this.productType = productType;
    this.region = region;
    this.originatingAsset = originatingAsset;
    OneConfigEmbed.heartbeatCb = heartbeatCb;
    OneConfigEmbed.saveConfigCb = saveConfigCb || addToCart;
    OneConfigEmbed.modelDataFailCb = modelDataFailCb;
    OneConfigEmbed.bomUpdateCb = bomUpdateCb;
    OneConfigEmbed.onSearchSKU = onSearchSKU;
    OneConfigEmbed.onAddRSKUToQuote = onAddRSKUToQuote;
    OneConfigEmbed.gateway = gateway;
    OneConfigEmbed.token = token;
    OneConfigEmbed.enableExport = enableExport;
    OneConfigEmbed.spcStartDate = spcStartDate;
    OneConfigEmbed.config = {
      banded: false,
      configId: config.configId,
      country: config.country,
      isStandAloneAccess: false,
      language: config.language,
      marketProgramName: config.marketProgramName,
      marketProgramType: config.marketProgramType,
      mdcpOrgId: config.mdcpOrgId,
      originatingAsset: config.originatingAsset,
      priceListType: config.priceListType,
      productType: config.productType,
      region: config.region,
      resellerId: config.resellerID || "",
      routeToMarket: config.routeToMarket,
      storeFront: storeFront || "WCS",
      userType: config.userType || "PARTNER"
    };

    OneConfigEmbed.modelConfig = {
      region: config.region,
      language: config.language,
      productType: "",
      originatingAsset: config.originatingAsset,
      modelId: "",
      country: config.country,
      mDCPOrgID: config.mdcpOrgId,
      resellerId: config.resellerId || "",
      userType: config.userType || "PARTNER",
      storeFront: storeFront || "WCS",
      shipmentDate: new Date().toISOString().split("T")[0],
      routeToMarket: config.routeToMarket,
      marketProgramType: config.marketProgramName || "",
      marketProgramName: config.marketProgramType || "",
      bom: bomList,
      bomQty: bomList.map(partNo => ({
        productNumber: partNo,
        quantity: "1"
      }))
    };

    if (config.priceDesc) {
      OneConfigEmbed.config.priceDesc = config.priceDesc;
      OneConfigEmbed.config.priceId = config.priceId;
      OneConfigEmbed.config.contractId = config.contractId || "";
      OneConfigEmbed.config.openMarketId = config.openMrktId || "";
      if (!showCLIN) OneConfigEmbed.config.contractId = "";
    } else {
      // assume pricingGeo, currency, incoterm is used to define price data
      OneConfigEmbed.config.pricingGeo = config.pricingGeo;
      OneConfigEmbed.config.incoTerms = config.incoTerms;
      OneConfigEmbed.config.currency = config.currency;
    }
    OneConfigEmbed.categoryName = "Business Laptop PCs";
    OneConfigEmbed.showCLIN = showCLIN;
    OneConfigEmbed.showOM = showOpenMarket || showOM || false;
    OneConfigEmbed.showHeader = showHeader;
    OneConfigEmbed.updateConfig = updateConfig;
    OneConfigEmbed.showOCFeatures = showOCFeatures;
    OneConfigEmbed.isReconfigure = isReconfigure;
    OneConfigEmbed.retainConfigID = retainConfigID;
    OneConfigEmbed.hideTechAV = hideTechAV;
    OneConfigEmbed.buttonTextToCart = buttonTextToCart;
    OneConfigEmbed.showProductSummary = showProductSummary;
    OneConfigEmbed.showSustainability = showSustainability;
    OneConfigEmbed.language = language;
    OneConfigEmbed.enableBomDeltaPrice = enableBomDeltaPrice;
    OneConfigEmbed.pricingCb = pricingCb;
    OneConfigEmbed.isBomCb = isBomCb;
    OneConfigEmbed.exitCb = exitCb;
    OneConfigEmbed.requestConfigCb = requestConfigCb;
    OneConfigEmbed.showRequestConfig = showRequestConfig;
    OneConfigEmbed.showListPriceText = showListPriceText;
    OneConfigEmbed.showStartingPointPrice = showStartingPointPrice;
    OneConfigEmbed.showSaveConfig = showSaveConfig;
    OneConfigEmbed.showReset = showReset;
    OneConfigEmbed.companyId = companyId;
    OneConfigEmbed.productAvailability = productAvailability || false;
    OneConfigEmbed.storeFront = storeFront || "WCS";
    OneConfigEmbed.removeDuplicateSKU = removeDuplicateSKU;
    OneConfigEmbed.scrollToChoice = scrollToChoice;
    OneConfigEmbed.showSearchSKU = showSearchSKU;
    OneConfigEmbed.showRequestSKU = showRequestSKU;
    OneConfigEmbed.userEmail = userEmail;
    OneConfigEmbed.enableLocalization = enableLocalization;
    OneConfigEmbed.isReConfig = true;
  },
  reConfigureInt: async function (initConfig) {
    try {
      this._downloadExternalRss(
        this._applyStyles(this._patchProcess(initConfig))
      );
      this.reConfigureEmbedSetup(initConfig);
      await this._reConfigBoostrap();
      return Promise.resolve("ReConfigurator started");
    } catch (e) {
      return Promise.reject(e);
    }
  },
  _reConfigBoostrap: async function () {
    try {
      const CATEGORY_MAP = {
        "Business Laptop PCs": "notebook",
        "Business Desktop PCs": "desktop",
        "Mobile Workstations": "mobilews",
        Workstations: "workstation",
        "Point of Sale Solutions": "pointofsale",
        "Voice Systems": "voiceSystems",
        "Video Systems": "videoSystems",
        Infrastructure: "infrastructure"
      };

      const DEFAULT_CATEGORY_NAME = "Business Laptop PCs";

      const getUiDataFileName = category => {
        if (CATEGORY_MAP[category]) return CATEGORY_MAP[category];

        const isPrinterCategory =
          category.toLowerCase().includes("printer") ||
          category.toLowerCase().includes("color laserjet");

        return isPrinterCategory ? "printer" : "desktop";
      };

      const getProductType = type => {
        const productTypes = {
          PY: "poly",
          PR: "print"
        };
        return productTypes[type] || "compute";
      };

      const { data } = await this._errorHandling(
        this._uploadConfigRestoration,
        this.uploadCacheKey
      );

      this._observe();
      const languageCode = this.getLanguageCode();
      const [ocfgInfo, translations] = await Promise.all([
        this._errorHandling(this._getOCFGInfo, this.ocfgCacheKey),
        this._errorHandling(
          this._getTranslation.bind(this, languageCode),
          this.translationCacheKey
        )
      ]);

      const {
        isValid,
        currency,
        ipgLocOptions,
        kybdLocOptions,
        monitorLocOptions,
        osLocOptions,
        modelRequirement,
        systemLocOptions,
        category,
        configName,
        configId
      } = data.uploadConfigRestoration.modelData;

      const categoryName = category || DEFAULT_CATEGORY_NAME;
      OneConfigEmbed.categoryName = categoryName;
      OneConfigEmbed.productType = getProductType(
        modelRequirement.productGroup
      );
      const headerInfo = {
        ...ocfgInfo.data.productSelectionV2,
        isValid,
        currency,
        ipgLocOptions,
        kybdLocOptions,
        monitorLocOptions,
        osLocOptions,
        modelRequirement,
        systemLocOptions,
        configId: configId || "",
        configName: configName || "N/A",
        refConfigId: "",
        uiSpec: getUiDataFileName(categoryName),
        docType: "QU"
      };

      window.modelJson = {
        ...data.uploadConfigRestoration.modelData,
        requiredMaterials: [],
        configId: this.configId || "",
        configName: this.configName || configName || "N/A",
        noneChaids: {}
      };

      window.spcDetails = {
        currency: currency,
        chaIds: window.modelJson.chaIds,
        items: window.modelJson.items
      };

      if (this.bundleReady) {
        this._reConfigurator(headerInfo, translations, languageCode);
      } else {
        return new Promise(resolve => {
          document.getElementById(this.scriptId).onload = () => {
            setTimeout(() => {
              OneConfigEmbed.bundleReady = true;
              resolve(
                this._reConfigurator(headerInfo, translations, languageCode)
              );
            }, 0);
          };
        });
      }
      setTimeout(() => {
        window.postMessage({
          type: "ONE_CONFIG_MODEL_DATA_READY"
        });
        this.modelDataReady = true;
        this.retryCount = 0;
      }, 0);
    } catch (err) {
      this._modelDataFailure(err);
      throw new Error(err);
    }
  },
  _reConfigurator: function (headerInfo, translations, languageCode) {
    const filterLevel = [
      this.originatingAsset.toUpperCase() || "OCIQ",
      this.productType,
      this.region,
      this.modelConfig.country,
      this.modelConfig.userType.toLowerCase()
    ];

    const { monitorQty, hideTab, hideChoice } = this.extractOCFGParams(
      headerInfo.ocfgParams || {},
      filterLevel
    );

    window.app({
      headerInfo: headerInfo,
      showCLIN: this.showCLIN,
      showSustainability: this.showSustainability,
      showOM: this.showOM,
      monitorQty,
      hideTab,
      hideChoice,
      showProductSummary: this.showProductSummary,
      showHeader: this.showHeader,
      buttonTextToCart: this.buttonTextToCart,
      enableBomDeltaPrice: this.enableBomDeltaPrice,
      enableExport: this.enableExport,
      updateConfig: this.updateConfig,
      translations,
      languageCode,
      language: this.language,
      modelAvailable: this.modelDataReady,
      showOCFeatures: this.showOCFeatures,
      isReconfigure: this.isReconfigure,
      retainConfigID: this.retainConfigID,
      region: this.region,
      hideTechAV: this.hideTechAV,
      showCancelConfig: typeof this.exitCb === "function",
      showRequestConfig: this.showRequestConfig,
      showListPriceText: this.showListPriceText,
      showStartingPointPrice: this.showStartingPointPrice,
      showSaveConfig: this.showSaveConfig,
      showReset: this.showReset,
      companyId: this.companyId,
      removeDuplicateSKU: this.removeDuplicateSKU,
      originatingAsset: this.originatingAsset,
      storeFront: this.storeFront,
      productAvailability: this.productAvailability,
      scrollToChoice: this.scrollToChoice,
      productType: this.productType,
      showSearchSKU: this.showSearchSKU,
      showRequestSKU: this.showRequestSKU,
      userEmail: this.userEmail,
      userType: this.modelConfig.userType,
      businessModel: this.config.routeToMarket,
      spcStartDate: this.spcStartDate,
      country: this.config.country,
      mdcpOrgId: this.config.mdcpOrgId,
      enableLocalization: this.enableLocalization,
      // always disable choices in reconfig
      disableChoices: this.isReConfig
    });
  },
  _uploadConfigurator: function (headerInfo, translations, languageCode) {
    const filterLevel = [
      this.originatingAsset.toUpperCase() || "OCIQ",
      this.productType,
      this.region,
      this.modelConfig.country,
      this.modelConfig.userType.toLowerCase()
    ];

    const { monitorQty, hideTab, hideChoice } = this.extractOCFGParams(
      headerInfo.ocfgParams || {},
      filterLevel
    );
    window.app({
      headerInfo: headerInfo,
      showCLIN: this.showCLIN,
      showSustainability: this.showSustainability,
      showOM: this.showOM,
      monitorQty,
      hideTab,
      hideChoice,
      showProductSummary: this.showProductSummary,
      showHeader: this.showHeader,
      buttonTextToCart: this.buttonTextToCart,
      enableBomDeltaPrice: this.enableBomDeltaPrice,
      enableExport: this.enableExport,
      updateConfig: this.updateConfig,
      translations,
      languageCode,
      language: this.language,
      modelAvailable: this.modelDataReady,
      showOCFeatures: this.showOCFeatures,
      isReconfigure: this.isReconfigure,
      isUploadFlow: this.isUploadFlow,
      isSKUIDClone: this.isSKUIDClone,
      skuId: this.skuId,
      region: this.region,
      hideTechAV: this.hideTechAV,
      showCancelConfig: typeof this.exitCb === "function",
      showRequestConfig: this.showRequestConfig,
      showListPriceText: this.showListPriceText,
      showStartingPointPrice: this.showStartingPointPrice,
      showSaveConfig: this.showSaveConfig,
      showReset: this.showReset,
      companyId: this.companyId,
      removeDuplicateSKU: this.removeDuplicateSKU,
      originatingAsset: this.originatingAsset,
      storeFront: this.storeFront,
      productAvailability: this.productAvailability,
      scrollToChoice: this.scrollToChoice,
      productType: this.productType,
      showSearchSKU: this.showSearchSKU,
      showRequestSKU: this.showRequestSKU,
      userEmail: this.userEmail,
      userType: this.userType || "PARTNER",
      country: this.config.country,
      mdcpOrgId: this.config.mdcpOrgId
    });
  },
  _uploadConfigRestoration: function () {
    return fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: "UPLOAD_CONFIG_RESTORATION_API_QUERY",
        variables: {
          filter: this.modelConfig
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      },
      cache: "no-cache"
    });
  },
  _evalRetry: function () {
    this.retryCount += 1;
    if (this.retryCount > 14) {
      window.postMessage({ type: "ONE_CONFIG_FAILURE" });
      this.exit();
      return false;
    }
    return true;
  },
  startOver: async function (timer) {
    if (this._evalRetry()) {
      const timeout = () =>
        new Promise(resolve => {
          setTimeout(resolve, timer || 0);
        });
      await timeout();
      this.cleanUp();
      const loaderDiv = document.createElement("div");
      loaderDiv.id = "configLoader";
      const loaderUrl = this.bucketURL + "loading.gif";
      loaderDiv.setAttribute(
        "style",
        `background: #e8e8e8 url(${loaderUrl}) no-repeat center content-box; height:100vh;margin:50px`
      );
      document.getElementById("oneConfigEmbed").appendChild(loaderDiv);
      await (this.isReConfig ? this._reConfigBoostrap() : this._bootstrap());
      document.getElementById("configLoader").remove();
    }
  },
  saveConfigSuccess: function (payload) {
    window.postMessage({
      type: "SAVE_CONFIG_SUCCESS",
      payload
    });
  },
  saveConfigFail: function (payload) {
    window.postMessage({
      type: "SAVE_CONFIG_FAIL",
      payload
    });
  },
  _bomUpdate: function (payload) {
    document.getElementById(this.containerId).dataset.bomGrandPrice =
      payload.bomGrandPrice;
    if (this.bomUpdateCb) this.bomUpdateCb(this.isBomCb ? payload : undefined);
  },
  _saveConfig: function (payload, actionType) {
    const {
      configBomWithPrices,
      productDescription,
      configName,
      retainConfigID,
      restrictAddtoCart,
      ...apiPayload
    } = payload;
    fetch(this.gateway, {
      method: "POST",
      body: JSON.stringify({
        query: `mutation createConfiguration($input: NewSaveConfiguration!) {\n  createConfiguration(input: $input)\n}\n`,
        variables: {
          input: { ...apiPayload, configName }
        }
      }),
      headers: {
        authorization: `Bearer ${this.token}`,
        "content-type": "application/json"
      }
    })
      .then(i => i.json())
      .then(async json => {
        if (json.data) {
          const {
            createConfiguration: { configId }
          } = json.data;
          if (this.showSaveConfig && actionType === "saveConfig") {
            OneConfigEmbed.config.configId = configId;
            this.cleanUp();
            this[this.modelCacheKey] = "";
            this[this.translationCacheKey] = "";
            this[this.refConfigCacheKey] = "";
            this[this.headerCacheKey] = "";
            this[this.uploadCacheKey] = "";
            this[this.ocfgCacheKey] = "";
            try {
              await this._bootstrap();
              this.saveConfigSuccess({
                actionType,
                createdConfigID: `${configId}`
              });
              return Promise.resolve("configurator started");
            } catch (e) {
              return Promise.reject(e);
            }
          } else if (
            json.data &&
            this.saveConfigCb &&
            (actionType === "addToCart" ||
              (this.showOCFeatures && actionType === "saveConfig"))
          ) {
            this.saveConfigSuccess({
              actionType,
              createdConfigID: this.showOCFeatures ? `${configId}` : null
            });
            setTimeout(() => {
              if (
                this.showOCFeatures &&
                this.isReconfigure &&
                !restrictAddtoCart
              ) {
                this.saveConfigCb(null, {
                  configName,
                  configID: configId,
                  components: configBomWithPrices,
                  retainConfigID,
                  productCategory: payload?.hierarchy?.productType,
                  refConfigID: payload?.configHeader?.refConfigId
                });
              } else {
                if (!restrictAddtoCart) {
                  this.saveConfigCb(
                    null,
                    configId,
                    this.isBomCb ? configBomWithPrices : undefined,
                    ...(this.showOCFeatures
                      ? [
                          payload?.configHeader?.refConfigId ||
                            this.config.configId,
                          this.productType,
                          productDescription,
                          retainConfigID,
                          payload?.hierarchy?.productType
                        ]
                      : [])
                  );
                }
              }
            }, 0);
          }
        }
        if (json.errors && this.saveConfigCb && actionType === "saveConfig") {
          this.saveConfigFail({
            actionType
          });
        } else if (
          json.errors &&
          this.saveConfigCb &&
          actionType === "addToCart"
        ) {
          this.saveConfigCb(json.errors);
        }
      })
      .catch(error => {
        this.saveConfigFail({
          actionType: actionType
        });
        if (this.saveConfigCb && actionType === "addToCart") {
          this.saveConfigCb(error);
        }
      });
  },
  _sendMail: function (payload) {
    const formData = new FormData();
    const operations = {
      query:
        "mutation sendMail($filter: sendMailRequest) { sendMail(filter: $filter) }",
      variables: {
        filter: {
          ...payload,
          attachment: [null]
        }
      },
      operationName: "sendMail"
    };

    formData.append("operations", JSON.stringify(operations));
    formData.append(
      "map",
      JSON.stringify({ 0: ["variables.filter.attachment.0"] })
    );
    formData.append("0", payload.attachment);
    fetch(this.gateway, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${this.token}`
      },
      cache: "no-cache"
    })
      .then(i => i.json())
      .then(json => {
        if (json.data) this.requestConfigSuccess();
        if (json.errors) this.requestConfigFail();
      })
      .catch(() => {
        this.requestConfigFail();
      });
  },
  requestConfigSuccess: function () {
    window.postMessage({
      type: "REQUEST_CONFIG_SUCCESS"
    });
  },
  requestConfigFail: function () {
    window.postMessage({
      type: "REQUEST_CONFIG_FAIL"
    });
  },
  requestRSKU: function (payload) {
    window.postMessage({
      type: "REQUEST_RSKU",
      payload
    });
  },
  addToQuoteFailed: function () {
    window.postMessage({
      type: "ADD_TO_QUOTE_FAILED"
    });
  },
  cleanUp: function () {
    delete window.modelJson;
    delete window.spcDetails;
    delete window.pricing;
    this.modelDataReady = false;
    OneConfigEmbed.observer.disconnect();
    window.appInstance.$destroy();
    const oneConfig = document.querySelector("one-config");
    if (oneConfig) oneConfig.remove();
    window.removeEventListener("message", this.listener);
  },
  exit: function () {
    setTimeout(() => {
      this.cleanUp();
      if (this.exitCb) this.exitCb();
    }, 0);
  },
  requestSuccess: function () {
    setTimeout(() => {
      if (this.requestConfigCb) {
        this.cleanUp();
        this.requestConfigCb();
      }
    }, 0);
  }
};
window.OneConfigEmbed = OneConfigEmbed;